import React, { useEffect, useState } from "react";

import "./App.css";
import {
  onConnect,
  sendTransaction,
  disconnect,
  changeNetwork,
} from "@sherry234/sdkapp";
import { ClientJS } from "clientjs";
import { ethers } from "ethers";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function App() {
  const [address, setAddress] = useState("");
  const [connected, setConnected] = useState(false);
  const [tokens, setTokens] = useState([]);
  const [value, setValue] = useState("");
  const [toAddress, setToAddress] = useState("");
  const [txHash, setTxHash] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const onConnectionStatus = (status: any) => {
    console.log("🚀 ~ file: App.tsx:10 ~ onConnectionStatus ~ status:", status);
    setConnected(status);
    return {};
  };

  console.log("testing jenkins");

  const onTransactionComplete = (txHash: any) => {
    setTxHash(txHash);
    setIsOpen(true);
  };
  const handleAddressAndHoldingReceived = (payload: any) => {
    console.log("Received Address ana holdin: ", payload);
    if (payload.address && payload.holding) {
      setAddress(payload.address);
      setTokens(payload.holding?.[payload.address].tokens);
    } else {
      console.log(
        "handleAddressAndHoldingReceived on disconnect from stash wallet",
        payload
      );
      setAddress("");
      setTokens([]);
    }
  };
  const onAddressReceived = (receivedAddress: string) => {
    console.log("Received Address: ", payload);
    setAddress(receivedAddress);
  };
  const payload = {
    to: "0x8f3cf7ad23cd3cadbd9735aff958023239c6a063",
    value: "0",
    calldata:
      "0xa9059cbb000000000000000000000000da0682da36943b411526a9edcf484ec02c830c680000000000000000000000000000000000000000000000000de0b6b3a7640000",
  };
  useEffect(() => {
    //  chro.tabs.query({ url: "http://localhost:3001/*" }, function (tabs) {
    //       if (tabs.length > 0) {
    //         console.log("Website is open in at least one tab.");
    //       } else {
    //         console.log("Website is not open in any tab.");
    //       }
    //     });
    // @ts-ignore
    // console.log("ccccccccccccccccccccccc", chrome.csi());
    // window.open("http://localhost:8080", "WikipediaWindow");

    const client = new ClientJS();

    // Get the client's fingerprint id
    const fingerprint = client.getFingerprint();
    console.log(
      "🚀 ~ file: useApp.tsx:678 ~ useEffect ~ fingerprint:",
      fingerprint
    );

    // Print the 32bit hash id to the console
    console.log(fingerprint);
  }, []);
  return (
    <div className="App">
      {connected ? (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <button
            style={{
              marginRight: "auto",
              marginLeft: "20px",
              border: "none",
              background: "black",
              padding: "10px",
              color: "white",
              borderRadius: "15px",

              cursor: "pointer",
            }}
            onClick={async () => {
              changeNetwork();
              // const data = await onConnect(
              //   handleAddressReceived,
              //   onConnectionStatus
              // );
              // console.log("🚀 ~ file: App.tsx:51 ~ App ~ data:", data);
            }}
          >
            Change network
          </button>
          <p
            style={{
              border: "2px solid gray",
              borderRadius: "15px",
              padding: "10px",
              background: "grey",
              color: "white",
            }}
          >
            {address}
          </p>
          <button
            style={{
              marginLeft: "20px",
              marginRight: "40px",
              border: "none",
              background: "black",
              padding: "10px",
              color: "white",
              borderRadius: "15px",
              cursor: "pointer",
            }}
            onClick={async () => {
              disconnect(onAddressReceived, onConnectionStatus);
              setTokens([]);
              // const data = await onConnect(
              //   handleAddressReceived,
              //   onConnectionStatus
              // );
              // console.log("🚀 ~ file: App.tsx:51 ~ App ~ data:", data);
            }}
          >
            Disconnect
          </button>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "20px",
            marginRight: "40px",
          }}
        >
          <button
            style={{
              border: "none",
              background: "black",
              padding: "10px",
              color: "white",
              borderRadius: "15px",
              marginLeft: "15px",
              cursor: "pointer",
            }}
            onClick={async () => {
              const data = await onConnect(
                handleAddressAndHoldingReceived,
                onConnectionStatus
              );
              console.log("🚀 ~ file: App.tsx:51 ~ App ~ data:", data);
            }}
          >
            Connect to Stash
          </button>
        </div>
      )}

      {!connected ? (
        <></>
      ) : (
        <>
          {/* <button
            onClick={async () => {
              disconnect(onAddressReceived, onConnectionStatus);
              setTokens([]);
              // const data = await onConnect(
              //   handleAddressReceived,
              //   onConnectionStatus
              // );
              // console.log("🚀 ~ file: App.tsx:51 ~ App ~ data:", data);
            }}
          >
            Disconnect
          </button> */}

          <div style={{ display: "flex" }}>
            <div
              style={{
                marginLeft: "20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <h3>Your Assets</h3>
              {tokens.map((singleToken: any) => {
                return (
                  <>
                    <div style={{ display: "flex" }}>
                      <p style={{ color: "blue", marginRight: "10px" }}>
                        {singleToken?.tokenName}
                      </p>
                      <p style={{ color: "green" }}>
                        {singleToken?.tokenBalance}
                      </p>
                    </div>
                  </>
                );
              })}
            </div>
            <div
              style={{
                marginLeft: "20%",
                alignSelf: "center",
                justifySelf: "center",
              }}
            >
              <div>
                <h3>Enter matic amount to transfer</h3>
                <input
                  placeholder="Enter matic amount"
                  onChange={(e) => setValue(e.target.value)}
                  type="number"
                  style={{
                    border: "2px solid gray",
                    outline: "none",
                    padding: "10px",
                    borderRadius: "12px",
                    width: "250px",
                  }}
                />
                <h3>Enter Recipient Address</h3>
                <input
                  style={{
                    border: "2px solid gray",
                    outline: "none",
                    padding: "10px",
                    borderRadius: "12px",
                    width: "250px",
                  }}
                  placeholder="0x123...."
                  onChange={(e) => setToAddress(e.target.value)}
                />
              </div>
              <button
                style={{
                  border: "none",
                  background: "black",
                  padding: "10px",
                  color: "white",
                  borderRadius: "15px",
                  marginLeft: "15px",
                  marginTop: "20px",
                  cursor:
                    toAddress.length === 42 && +value > 0
                      ? "pointer"
                      : "not-allowed",
                  opacity: toAddress.length === 42 && +value > 0 ? 1 : 0.5,
                }}
                onClick={() => {
                  // sendTransaction(payload.calldata, payload.value, payload.to);
                  const payload = {
                    to: toAddress,
                    value: ethers.parseUnits(String(value), 18).toString(),
                    calldata: "0x",
                  };
                  console.log(
                    "🚀 ~ file: App.tsx:141 ~ App ~ payload:",
                    payload
                  );
                  sendTransaction(
                    payload.calldata,
                    payload.value,
                    payload.to,
                    onTransactionComplete
                  );
                }}
              >
                Send TX
              </button>
            </div>
          </div>
        </>
      )}

      {/* {connected && (
       
      )} */}
      {/* {connected && (
      
      )} */}
      {/* {connected && (
       
      )} */}
      {/* {connected &&
       } */}
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        onRequestClose={() => {
          setTxHash("");
          setIsOpen(false);
        }}
      >
        <h2>Transaction Completed</h2>
        <a href={`https://polygonscan.com/tx/${txHash}`} target="blank">
          View on Polygonscan
        </a>
      </Modal>
    </div>
  );
}

export default App;
